import React from "react"
import { Link } from "gatsby"

const Jumbo = () => {

    return (
        <section className="tc tl-l jumbotron">
            <div className="overlay">
                <div className="container-outer-xs container-outer-m container-outer tc-l">
                    <h1 className="pt0 pt4-l">Comprehensive Screening &<br/>Advanced Treatment</h1>
                    <p className="desktop hide">Incision-Free. Radiation Free.</p>
                    <p className="pt3">Leading the way with advanced prostate MRI screening and the revolutionary, incision-free TULSA Procedure for optimal prostate health. Our experienced RadNet Prostate team has performed more prostate MRI exams than any other group in the country, ensuring unmatched expertise and quality care.</p>
                    <Link to="prostate-mri" className="button cta grow">Prostate MRI</Link>
                    <Link to="tulsa" className="button cta grow">TULSA Pro</Link>
                    <Link to="locations" className="button cta grow">Find A Location</Link>
                </div>
            </div>
            <img className="tulsa-video" src="https://cdnwest.radnet.com/img/unmask-web-banner.jpg" />
        </section>
    )
}

export default Jumbo
